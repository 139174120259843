export default {
  PAGE: {
    CUSTOMER_MANAGEMENT: 'Cliente | Clientes',
  },
  LABELS: {
    MY_SUBSCRIBERS: 'Meu assinante | Meus assinantes',
    CPF: 'CPF',
    NOME: 'Nome',
    EMAIL: 'Email',
    GRUPO_ASSINANTE: 'Grupo de Assinantes',
    TIPO_DOCUMENTO: 'Tipo de Documento',
    STATUS: 'Status',
    ACTION: 'Ação | Ações',
    SEARCH_GROUP: 'Grupo | Grupos',
    SEARCH_DOC: 'Documento | Documentos',
    ADD_SIGN: 'Incluir assinante | Incluir assinantes',
    TITLE: 'Deseja realmente inativar este assinante?',
    SUBTITLE: 'O Assinante será inativado',
  },
  FORM: {
    ACTION: {
      CANCEL: 'Cancelar',
      CONFIRM: 'Excluir',
      CONFIRM_INACTIVE: 'Inativar',
      DOCUMENT: 'Documento | Documentos',
      ATTORNEY: 'Procurador | Procuradores',
      SPOUSE: 'Vincular | Desvincular Cônjuge',
      TYPE_DOCUMENT: 'Tipo de Documento | Tipos de Documentos',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
  },
}
