export const routesDocumentsTypes = [
  {
    name: 'ContractorDocumentsTypes',
    path: 'firp/:path/:id/tipos-de-documentos',
    component: () => import('@/pages/FIRP/DocumentsTypes/ListPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'ContractorDocumentsTypes',
    path: 'firp/contratante/:id/tipos-de-documentos',
    component: () => import('@/pages/FIRP/DocumentsTypes/ListPage'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'CreateDocumentType',
    path: 'firp/:idContractor/tipos-de-documentos',
    component: () => import('@/pages/FIRP/DocumentsTypes/CreatePage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'UpdateDocumentType',
    path: 'firp/:idContractor/tipos-de-documentos/:idDocumentType',
    component: () => import('@/pages/FIRP/DocumentsTypes/UpdatePage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
]
