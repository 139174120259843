export default {
  PAGE: {
    QUALIFIER_MANAGEMENT: 'Qualificador | Qualificadores',
  },
  LABELS: {
    NEW_QUALIFIER: 'Novo Qualificador',
    QUALIFIER_NAME: 'Nome do Qualificador',
    QUALIFIER_DESCRIPTION: 'Descrição do Qualificador',
    TABLE: {
      NAME: 'Nome',
      DESCRIPTION: 'Descrição',
    },
    DELETE_MODAL: {
      TITLE: 'Deseja realmente excluir o qualificador?',
      SUBTITLE: 'O qualificador sera excluido',
      ACTION: {
        DELETE: 'EXCLUIR',
        CANCEL: 'CANCELAR',
      },
    },
  },
  FORM: {
    ACTION: {
      ADD: 'Adicionar Qualificador',
      DELETE: 'Deletar Qualificador',
      EDIT: 'Editar Qualificador',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
  },
}
