export const routesGroupsAssignments = [
  {
    name: 'RouterContext',
    path: 'firp/grupo-de-assinantes',
    component: () => import('@/pages/FIRP/GroupAssignment/RouterContext.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:firp-group-assignment-list'],
    },
    children: [
      {
        name: 'GroupAssignment',
        path: '',
        component: () =>
          import('@/pages/FIRP/GroupAssignment/GroupAssignmentPage.vue'),
        meta: {
          rules: ['formalization:firp-group-assignment-list'],
        },
      },
      {
        name: 'GroupAssignmentCreate',
        path: 'criacao',
        component: () =>
          import('@/pages/FIRP/GroupAssignment/CrudGroupAssignmentPage.vue'),
        meta: {
          rules: ['formalization:firp-group-assignment-add'],
        },
      },
      {
        name: 'GroupAssignmentEdit',
        path: 'edit/:id',
        component: () =>
          import('@/pages/FIRP/GroupAssignment/CrudGroupAssignmentPage.vue'),
        meta: {
          rules: ['formalization:firp-group-assignment-edit'],
        },
      },
      {
        name: 'GroupAssignmentView',
        path: 'view/:id',
        component: () =>
          import('@/pages/FIRP/GroupAssignment/CrudGroupAssignmentPage.vue'),
        meta: {
          rules: ['formalization:firp-group-assignment-list'],
        },
      },
    ],
  },
]
