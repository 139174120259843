export default {
  PAGE: {
    SIGNER_MANAGEMENT: 'Assinante | Assinantes',
    NEW_SIGNER: 'Novo Assinante',
  },
  LABELS: {
    NEW_SIGNER_ADD: 'Cadastrar Novo Assinante | Cadastrar Novos Assinantes',
    EDIT_SIGNER: 'Editar Assinante | Editar Assinantes',
    NEW_SPOUSE_ADD: 'ADICIONAR CÔNJUGE',
    SPOUSE: 'Cônjuge',
    TYPE_DOCUMENT: 'Tipo de Documento',
    PAPER: 'Papel',
    QUALIFIER: 'Qualificador',
    CPF: 'CPF',
    NOME: 'NOME',
    NOME_COMPLETO: 'Nome Completo',
    EMAIL: 'e-mail',
    ESTADO_CIVIL: 'Estado civil',
    DOCUMENTS: 'Documentos',
    TITLE: 'Cadastrar Novo Documento?',
    SUBTITLE: 'Você será direcionado para o Cadastro de Documentos.',
    SUBTITLE2:
      'Os dados inseridos não serão salvos, deseja continuar mesmo assim?',
    GROUP_MODAL: {
      TITLE: 'Grupo de Assinantes',
      SUBTITLE: 'Listagem dos Grupos deste Assinante',
      TOOLTIP: 'Clique para obter todas as informações do grupo de assinantes',
    },
    DOCUMENTS_MODAL: {
      TITLE: 'Documentos',
      SUBTITLE: 'Listagem de Documentos deste Assinante',
      TOOLTIP:
        'Clique para obter todas as informações dos documentos do assinante',
    },
    INVALID_MAIL: 'Email inválido. Por favor, verifique o cadastro.',
  },
  FORM: {
    ACTION: {
      CANCEL: 'Cancelar',
      CONFIRM: 'Continuar',
      NEW_DOCUMENT: 'Novo Documento',
      DISABLE_SIGNER: 'Inativar Assinante',
      CLOSE: 'FECHAR',
      BIND_SPOUSE: 'Vincular Cônjuge',
      UNBIND_SPOUSE: 'Desvincular Cônjuge',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
    MSG004: 'Desvinculado com sucesso',
    MSG005: 'Vinculado com sucesso',
  },
}
