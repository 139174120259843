export const routesContractor = [
  // Contratante
  {
    name: 'Contractor',
    path: 'firp/contratante',
    component: () => import('@/pages/FIRP/Contractor/ContractorPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:firp-contractor-list'],
    },
  },
  {
    name: 'ContractorCustomer',
    path: 'firp/contratante/:idContratante/cliente',
    component: () => import('@/pages/FIRP/Contractor/CustumerPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
  {
    name: 'ContractorServiceProvider',
    path: 'firp/contratante/:idContratante/prestador-de-servico',
    component: () => import('@/pages/FIRP/Contractor/ServiceProviderPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
]
