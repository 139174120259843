export default {
  PAGE: {
    SPOUSE_MANAGEMENT: 'Cônjuge',
  },
  LABELS: {
    DUO_SIGNER: 'Assina em conjunto',
    NEW_SIGNER_ADD: 'Cadastrar Novo Assinante | Cadastrar Novos Assinantes',
    NEW_SPOUSE_ADD: 'ADICIONAR CÔNJUGE',
    SPOUSE: 'Cônjuge',
    TYPE_DOCUMENT: 'Tipo de Documento',
    PAPER: 'Papel',
    QUALIFIER: 'Qualificador',
    CPF: 'CPF',
    NOME: 'NOME',
    NOME_COMPLETO: 'Nome Completo',
    EMAIL: 'e-mail',
    ESTADO_CIVIL: 'Estado civil',
    DOCUMENTS: 'Documentos',
    TITLE: 'Cadastrar Novo Documento?',
    SUBTITLE: 'Você será direcionado para o Cadastro de Documentos.',
    SUBTITLE2:
      'Os dados inseridos não serão salvos, deseja continuar mesmo assim?',
    SEARCH_DOC: 'Documento | Documentos',
    ADD_SIGN: 'Incluir Assinante | Incluir Assinantes',
  },
  FORM: {
    ACTION: {
      CANCEL: 'Cancelar',
      CONFIRM: 'Continuar',
      NEW_DOCUMENT: 'Novo Documento',
      DISABLE_SIGNER: 'Inativar Assinante',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
  },
}
