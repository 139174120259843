export const routesServiceProvider = [
  {
    name: 'ServiceProvider',
    path: 'firp/prestador-de-servico',
    component: () => import('@/pages/FIRP/ServiceProvider/ServiceProviderPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
  {
    name: 'ServiceProviderSignerDocuments',
    path: 'firp/prestador-de-servico/:id/documentos',
    component: () => import('@/pages/FIRP/ServiceProvider/Documents/DocumentsPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
]
