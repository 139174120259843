export const routerSigner = [
  {
    name: 'SignerID',
    path: 'firp/assinante/:id',
    props: true,
    component: () => import('@/pages/FIRP/Signer/SignerPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'Signer',
    path: 'firp/:id/:path/assinante/',
    props: true,
    component: () => import('@/pages/FIRP/Signer/SignerPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'SignerDocuments',
    path: 'firp/assinante/:id_contractor/documentos/:id_signer',
    props: true,
    component: () => import('@/pages/FIRP/Signer/Documents/DocumentsPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'NewSigner',
    path: 'firp/assinante/:id/:path/assinante',
    component: () => import('@/pages/FIRP/Signer/SignerCreatePage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
  {
    name: 'AttachDocumentSigner',
    path: 'firp/assinante/:idContrante/:idCliente/procurador/documentos-de-procuracao/:idProcurador/anexar-documentos',
    component: () =>
      import('@/pages/FIRP/Signer/Attorneys/AttachDocumentPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
]
