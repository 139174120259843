import { defineStore } from 'pinia'

export const useNotifyStore = defineStore('notify', {
  state: () => ({
    error: '',
    alerta: '',
    info: '',
    success: '',
    errorNotLogged: '',
    successNotLogged: '',
  }),
  getters: {
    getError: (state) => state.error,
    getSuccess: (state) => state.success,
    getErrorNotLogged: (state) => state.errorNotLogged,
    getSuccessNotLogged: (state) => state.successNotLogged,
  },
  actions: {
    fetchError(message) {
      setTimeout(() => {
        this.error = message
      }, 100)
    },
    fetchSuccess(message) {
      setTimeout(() => {
        this.success = message
      }, 100)
    },
    fetchErrorNotLogged(message) {
      setTimeout(() => {
        this.errorNotLogged = message
      }, 100)
    },
    fetchSuccessNotLogged(message) {
      setTimeout(() => {
        this.successNotLogged = message
      }, 100)
    },
  },
})
