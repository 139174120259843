export default {
  LABELS: {
    NEW_PAPER: 'Novo Papel',
    PAPER_NAME: 'Nome do Papel',
    PAPER_DESCRIPTION: 'Descrição do Papel',
    TABLE: {
      NAME: 'Nome',
      DESCRIPTION: 'Descrição',
    },
    DELETE_MODAL: {
      TITLE: 'Deseja realmente excluir o papel?',
      SUBTITLE: 'O papel sera excluido',
      ACTION: {
        DELETE: 'EXCLUIR',
        CANCEL: 'CANCELAR',
      },
    },
  },
  FORM: {
    ACTION: {
      ADD: 'Adicionar Papel',
      DELETE: 'Deletar Papel',
      EDIT: 'Editar Papel',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
  },
}
