export default {
  PAGE: {
    ATTACH_DOCUMENT: 'Anexar Documento de Procuração',
  },
  LABELS: {
    ENVIADO_EM: 'Enviado em',
    DATA_VIGENCIA: 'Data de Vigência',
    DOCUMENTO: 'Documento',
    ACTION: 'Ação | Ações',
  },
  FORM: {
    ACTION: {
      EDIT: 'Editar',
      ALERTS: 'Visualizar Alertas',
      DELETE: 'Excluir',
      DOWNLOAD: 'Baixar',
      CANCEL: 'Cancelar',
      CONFIRM: 'Excluir',
      DOCUMENT: 'Documento | Documentos',
      SIGNER: 'Assinante | Assinantes',
      CUSTOMER: 'Cliente | Clientes',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
  },
  MODAL_DELETE: {
    TITLE: 'Deseja realmente excluir este documento?',
    SUBTITLE: 'O documento será excluído',
    CONFIRM: 'Excluir',
    CANCEL: 'Cancelar',
  },
}
