import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import Quasar from 'quasar/src/vue-plugin.js';
import router from './router'
import App from './App.vue'
import quasarUserOptions from './quasar-user-options'
import 'quasar/dist/quasar.addon.css'
import { i18n } from './boot/i18n'
import { createPinia } from 'pinia'
import { router as routerUtils } from '@crdc/utils'

// Styles
import '@/styles/global.scss'

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {})
    },
  },
  handleInstance: (app) => {
    app.use(Quasar, quasarUserOptions)
    app.use(createPinia())
    app.use(router)
    routerUtils.guard(router)
    app.use(i18n)
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
