export default {
  PAGE: {
    DOCUMENT_MANAGEMENT: 'Documento | Documentos',
  },
  LABELS: {
    SIGNER: 'Assinante | Assinantes',
    TITLE: 'Cadastrar Novo Documento??',
    TITLE_MODAL_SIGNER: 'Assinantes Documento',
    SUBTITLE_MODAL_SIGNER: 'Listagem dos Assinantes deste Documento',
    SUBTITLE: 'Você será direcionado para o Cadastro de Documentos.',
    SUBTITLE2:
      'Os dados inseridos não serão salvos, deseja continuar mesmo assim?',
    DELETE_TITLE: 'Deseja realmente excluir o tipo de documento?',
    DELETE_SUBTITLE: 'Informe abaixo o motivo',
  },
  FORM: {
    ACTION: {
      CANCEL: 'Cancelar',
      CONFIRM: 'Continuar',
      DELETAR: 'EXCLUIR',
      SIGNER_VIEW: 'Visualizar Assinantes',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
    MSG004: 'Tipo de documento alterado com sucesso!',
    MSG005: 'Tipo de documento cadastrado com sucesso!',
  },
}
