import CONTRACTOR_MANAGEMENT from './contractorManagement'
import CUSTOMER_MANAGEMENT from './customerManagement'
import DOCUMENT_MANAGEMENT from './documentManagement'
import PAPER_MANAGEMENT from './paperManagement'
import QUALIFIER_MANAGEMENT from './qualifierManagement'
import SERVICE_PROVIDER_MANAGEMENT from './serviceProviderManagement'
import SIGNER_MANAGEMENT from './signerManagement'
import SPOUSE_MANAGEMENT from './spouseManagement'
import PROCURATOR_MANAGEMENT from './procuratorManagement'
import ATTORNEY_DOCUMENT from './attorneyDocument'
import ATTACH_DOCUMENT from './attachDocument'
import GROUP_ASSIGNMENT_MANAGEMENT from './groupAssignamentManagement'

export default {
  PAPER_MANAGEMENT,
  QUALIFIER_MANAGEMENT,
  CUSTOMER_MANAGEMENT,
  CONTRACTOR_MANAGEMENT,
  SIGNER_MANAGEMENT,
  DOCUMENT_MANAGEMENT,
  SERVICE_PROVIDER_MANAGEMENT,
  SPOUSE_MANAGEMENT,
  ATTORNEY_DOCUMENT,
  PROCURATOR_MANAGEMENT,
  ATTACH_DOCUMENT,
  GROUP_ASSIGNMENT_MANAGEMENT,
  PAGE: {
    HOME: 'Início',
    PRIVACY_POLICY: 'Política de Privacidade',
    CONTRACTOR: 'Contratante | Contratantes',
    CONTRACTOR_EDIT: 'Editar',
    CONTRACTOR_CREATE: 'Incluir Contratante',
    CONTRACTOR_VIEW: 'Visualizar',
    GRUPO_ASSINANTES: 'Grupo de Assinantes',
    CLIENT: 'Cliente | Clientes',
    PEOPLE: 'Pessoa | Pessoas',
    USER: 'Usuário | Usuários',
    USER_INVITE: 'Convidar Usuários',
    USER_GROUPS: 'Grupo de Usuários',
    PROFILE: 'Perfil | Perfis',
    FIRP: 'Gestão de FIRP',
    WORKFLOW: 'Gestão de Workflow',
    BOOKKEEPING: 'Escrituração',
    CLAUSE_LIBRARY: 'Biblioteca de Cláusulas',
    TYPE_OF_DOCUMENTS: 'Tipo de Documento | Tipo de Documentos',
    TEMPLATES: 'Template | Templates',
    DOCUMENT_MANAGEMENT: 'Gerir Documento | Gerir Documentos',
    STORAGE: 'Armazenamento',
    MESSENGER: 'Mensageria',
    MONITORING_NFE_CTE: 'Monitoramento NFE/CTE',
    MONITORING_GROUPS: 'Grupo de Monitoramento | Grupos de Monitoramento',
    DATA_ANALYSIS_SANITATION: 'Analise de Dados/Saneamento',
    DOCUMENT: 'Documento | Documentos',
    DOCUMENT_ASSET: 'Manual | Manuais',
    TERMS_OF_USE: 'Termos de Uso',
    MY_ACCOUNT: 'Minha Conta',
    HELP: 'Ajuda',
    TUTORIAL: 'Tutoriais',
    CHAT_ONLINE: 'Chat Online',
    FAQS: 'FAQs',
    QUESTIONS_AND_SUGGESTIONS: 'Dúvidas e Sugestões',
    LOGOUT: 'Sair',
    INVITE_USER: 'Convidar Usuários',
    BACK_HOME: 'Voltar para a página inicial',
    SERVICE_PROVIDER: 'Prestador de serviço | Prestadores de serviços',
    SERVICE_PROVIDER_CREATE: 'Incluir/ Associar Prestador de Serviço',
    GESTAO_QUALIFICADOR: 'Gestão de Qualificador | Gestão de Qualificadores',
    GESTAO_PAPEL: 'Gestão de Papel | Gestão de Papéis',
    GRUPO_ASSINANTE: 'Grupo de Assinantes | Grupos de Assinantes',
    SIGNER: 'Assinantes',
    ATTORNEY: 'Gestão de Procurador',
    ATTORNEY_DOCUMENT: 'Documentos de Procuração',
    CREATE_TYPE_OF_DOCUMENTS: 'Novo tipo de documento',
    VIEW_TYPE_OF_DOCUMENTS: 'Visualizar tipo de documento',
  },
}
