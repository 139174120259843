export const routesAttorney = [
  {
    name: 'SignerProcurator',
    path: 'firp/:type/:id/procurador',
    component: () => import('@/pages/FIRP/Signer/Procurator/ProcuratorPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'SignerProcuratorForm',
    path: 'firp/:type/:id/procurador/criacao',
    component: () => import('@/pages/FIRP/Signer/Procurator/ProcuratorCreatePage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'SignerProcuratorFormEdit',
    path: 'firp/:type/:id/procurador/edicao/:id_procurator',
    component: () => import('@/pages/FIRP/Signer/Procurator/ProcuratorCreatePage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'SignerProcuratorDocumentTypes',
    path: 'firp/:type/:id/procurador/documentos/:id_procurator',
    component: () => import('@/pages/FIRP/Signer/Procurator/ProcuratorCreatePage.vue'),
    meta: {
      onlyDocuments: true,
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
  {
    name: 'SignerProcuratorDocuments',
    path: 'firp/:type/:id/procurator/:attorneyId/documents',
    component: () =>
      import('@/pages/FIRP/Signer/Attorneys/DocumentsPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
]
