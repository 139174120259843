export const routesPaperManagement = [
  {
    name: 'PaperManagement',
    path: 'firp/gestao-de-papel',
    component: () =>
      import('@/pages/FIRP/PaperManagement/PaperManagementPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
]
