<template>
  <q-img alt="Image Logo" :src="logo" contain />
</template>

<script>
import logoWhite from '@/assets/logo_crdc_branco.png'
import logoColorful from '@/assets/logo_crdc_colorido.png'

export default {
  name: 'LogoPortal',
  props: {
    colorful: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logo: {
      get() {
        return this.colorful ? logoColorful : logoWhite
      },
    },
  },
}
</script>
