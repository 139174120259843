export default {
  PAGE: {
    PROCURATOR_MANAGEMENT: 'Gestão de Procurador',
    ASSOCIATE_PROCURATOR: 'Associar procurador',
  },
  LABELS: {
    PROCURATOR: 'Procurador | Procuradores',
    ADD_PROCURATOR: 'Incluir Procurador',
    TITLE: 'Deseja realmente inativar este procurador?',
    SUBTITLE: '',
  },
  ACTIONS: {
    EDIT: 'Editar',
    PROCURATION: 'Procuração',
    DOCUMENT_TYPE: 'Tipo de documento | Tipos de documentos',
    INACTIVE: 'Inativar procurador',
    CONFIRM: 'Excluir',
    CANCEL: 'Cancelar',
  },
}
