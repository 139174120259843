<template>
  <div>
    <q-drawer
      v-model="menuLeftOpenMax"
      side="left"
      show-if-above
      bordered
      :mini="miniState"
      class="bg-primary"
    >
      <q-list
        class="text-blue-grey-9"
        :style="isMobile ? 'marginTop: 8px' : 'marginTop: 68px'"
      >
        <template v-for="item in listItems" :key="item.id">
          <custom-item-drawer
            v-if="!item.subMenu"
            :item="item"
          ></custom-item-drawer>
          <q-expansion-item
            v-else
            :icon="item.icon"
            :label="item.label"
            class="text-white"
            expand-icon-class="text-white"
          >
            <custom-item-drawer
              v-for="subItem in item.subMenu"
              :item="subItem"
              :key="subItem.id"
              :inset-level="1"
            ></custom-item-drawer>
          </q-expansion-item>
        </template>
      </q-list>
    </q-drawer>
  </div>
</template>

<script>
import { event } from '@crdc/utils'
import CustomItemDrawer from './CustomItemDrawer.vue'
export default {
  components: {
    CustomItemDrawer,
  },
  data() {
    return {
      drawer: true,
      miniState: true,
      menuLeftOpenMax: false,
      listItems: [
        {
          id: 6,
          icon: 'home',
          label: this.$tc('PAGE.HOME', 1),
          pagePath: '/formalization-management',
          permission: 'formalization:home-formalization',
        },
        {
          id: 7,
          icon: 'admin_panel_settings',
          label: this.$tc('PAGE.FIRP', 1),
          permission: 'formalization:page-firp',
          subMenu: [
            {
              id: 1,
              icon: '',
              label: this.$tc('PAGE.GESTAO_QUALIFICADOR', 1),
              pagePath: '/formalization-management/firp/gestao-de-qualificador',
              permission: 'formalization:page-firp',
            },
            {
              id: 2,
              icon: '',
              label: this.$tc('PAGE.GESTAO_PAPEL', 1),
              pagePath: '/formalization-management/firp/gestao-de-papel',
              permission: 'formalization:page-firp',
            },
            {
              id: 3,
              icon: '',
              label: this.$tc('PAGE.GRUPO_ASSINANTE', 1),
              pagePath: '/formalization-management/firp/grupo-de-assinantes',
              permission: 'formalization:firp-group-assignment-list',
            },
            {
              id: 4,
              icon: '',
              label: this.$tc('PAGE.CONTRACTOR', 1),
              pagePath: '/formalization-management/firp/contratante',
              permission: 'formalization:firp-contractor-list',
            },
            {
              id: 5,
              icon: '',
              label: this.$tc('PAGE.CLIENT', 1),
              pagePath: '/formalization-management/firp/cliente',
              permission: 'formalization:page-firp',
            },
            {
              id: 6,
              icon: '',
              label: this.$tc('PAGE.SERVICE_PROVIDER', 1),
              pagePath: '/formalization-management/firp/prestador-de-servico',
              permission: 'formalization:page-firp',
            },
          ],
        },
        // {
        //   id: 8,
        //   icon: 'widgets',
        //   label: this.$tc('PAGE.WORKFLOW', 1),
        //   pagePath: '/formalization-management/workflows',
        //   permission: 'formalization:page-workflow',
        // },
        // {
        //   id: 9,
        //   icon: 'menu_book',
        //   label: this.$tc('PAGE.BOOKKEEPING', 1),
        //   pagePath: '/formalization-management/bookkeeping',
        //   permission: 'formalization:page-bookkeeping',
        // },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$q.screen.sm || this.$q.screen.xs
    },
  },
  created() {
    event.listen('NAVBAR:TOOLBAR:TOGGLE_MENU_LEFT', () => {
      if (this.miniState && this.menuLeftOpenMax) {
        this.miniState = false
      } else if (!this.miniState && this.menuLeftOpenMax) {
        this.menuLeftOpenMax = false
      } else {
        this.miniState = true
        this.menuLeftOpenMax = true
      }
    })
  },
}
</script>
