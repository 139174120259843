<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="col-12">
    <div
      class="row full-height col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 footer"
      :style="
        $q.screen.width <= 599
          ? 'height: 350px !important'
          : 'height: 70px !important; padding: 0 0px 0 50px; align-items: center;'
      "
    >
      <div
        v-if="$q.screen.width <= 599"
        class="col-xs-12"
        style="text-align: -webkit-center"
      >
        <LogoPortal
          colorful
          class="row col-xs-12 items-center"
          style="width: 87px"
        />
        <span class="tecnologia_simplifica">
          {{ $tc('DIV.FOOTER.MOBILE.TEXT.PART_1') }} <br />
          {{ $tc('DIV.FOOTER.MOBILE.TEXT.PART_2') }}
        </span>
      </div>
      <div
        v-if="$q.screen.width <= 599"
        class="col-xs-12 social-media items-center"
        style="margin-bottom: 30px"
      >
        <q-separator style="height: 1px; width: 95px" />
        <a
          href="https://www.facebook.com/crdccentralderegistros/"
          target="_blank"
        >
          <q-icon size="1em" name="fa-brands fa-facebook" />
        </a>
        <a
          href="https://www.instagram.com/centralderegistros_crdc/"
          target="_blank"
        >
          <q-icon size="1em" name="fa-brands fa-instagram" />
        </a>
        <a href="https://contate.me/crdc" target="_blank">
          <q-icon size="1em" name="fa-brands fa-whatsapp" />
        </a>
        <q-separator style="height: 1px; width: 95px" />
      </div>
      <span
        class="col-xl-3 col-lg-3 col-md-3 col-sm-2 col-xs-12 crdc_2022 text-h5"
        style="text-align: center !important"
        ><a class="termos-politica" @click="download('termo')">{{
          $tc('DIV.FOOTER.USE_TERMS')
        }}</a></span
      >
      <span
        class="col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-12 crdc_2022 text-h5"
        style="text-align: left !important"
        ><a class="termos-politica" @click="download('politica')">{{
          $tc('DIV.FOOTER.PRIVACY_POLICY')
        }}</a></span
      >
      <span
        v-if="$q.screen.width > 599"
        class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 crdc_2022 text-h5"
        style="text-align: left !important"
      >
        <span class="crdc" @click="goToHome()">{{
          $tc('DIV.FOOTER.CRDC')
        }}</span>
        | {{ $tc('DIV.FOOTER.COPYRIGHT') }}
      </span>
      <span
        v-else
        class="col-xl-6 col-lg-5 col-md-5 col-sm-5 col-xs-12 crdc_2022 text-h5 crdc"
        style="text-align: center !important"
        @click="goToHome()"
      >
        {{ $tc('DIV.FOOTER.MOBILE.SITE') }}
      </span>
      <div
        class="col-xl-2 col-lg-1 col-md-1 col-sm-2 col-xs-12 row justify-end"
        :style="$q.screen.width > 599 ? 'padding-right: 50px' : ''"
      >
        <div
          v-if="$q.screen.width > 599"
          class="social-media"
          :style="$q.screen.width > 599 ? 'width: 100px' : ''"
        >
          <a
            href="https://www.facebook.com/crdccentralderegistros/"
            target="_blank"
          >
            <q-icon size="1em" name="fa-brands fa-facebook" />
          </a>
          <a
            href="https://www.instagram.com/centralderegistros_crdc/"
            target="_blank"
          >
            <q-icon size="1em" name="fa-brands fa-instagram" />
          </a>
          <a href="https://contate.me/crdc" target="_blank">
            <q-icon size="1em" name="fa-brands fa-whatsapp" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import LogoPortal from '../../components/image/LogoPortal.vue'

export default defineComponent({
  name: 'ToolbarFooter',
  components: { LogoPortal },
  methods: {
    goToHome() {
      window.open('https://www.crdc.com.br', '_blank')
    },
    download(template) {
      this.$q.loading.show()
      if (template === 'termos') {
        window.open(
          'https://plt-termos-de-servicos.s3.amazonaws.com/terms.pdf',
          '_blank'
        )
      } else {
        window.open(
          'https://plt-termos-de-servicos.s3.amazonaws.com/privacy-terms.pdf',
          '_blank'
        )
      }
      this.$q.loading.hide()
    },
  },
})

</script>
<style lang="scss" scoped>
.footer {
  color: rgba(0, 0, 0, 0.6);
  background-color: #eef5fa !important;
  width: 100%;
  height: 50px;
}

.footer a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}
a:link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
}

.crdc_2022 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
}

.termos-politica {
  text-decoration: underline !important;
  cursor: pointer;
  color: #027be3 !important;
}

.social-media {
  display: flex;
  justify-content: space-evenly;
}

.crdc {
  text-decoration: underline;
  cursor: pointer;
  color: #027be3;
}

.tecnologia_simplifica {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

</style>

