<template>
  <q-item
    v-bind="$attrs"
    v-on="$on"
    v-ripple
    clickable
    class="text-white"
    exact-active-class="item-active"
    v-show="isAllowed([item.permission])"
    @click="() => $router.push(item.pagePath)"
  >
    <q-item-section v-if="item.icon" avatar>
      <q-icon :name="item.icon" />
    </q-item-section>
    <q-item-section>
      {{ item.label }}
    </q-item-section>

    <q-tooltip anchor="center right" self="center left" :offset="[10, 10]">
      {{ item.label }}
    </q-tooltip>
  </q-item>
</template>

<script>
import { router } from '@crdc/utils'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isAllowed(items) {
      return router.isAccessAllowed(items, this.$route.matched[0]?.name)
    },
  },
}
</script>
