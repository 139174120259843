export const routesCustomer = [
  {
    name: 'Customer',
    path: 'firp/cliente',
    component: () => import('@/pages/FIRP/Customer/CustomerPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
   {
    name: 'FIRPCustumerSignerDocuments',
    path: 'firp/cliente/:id/documentos',
    component: () => import('@/pages/FIRP/Customer/Documents/DocumentsPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
]
