import { createI18n } from 'vue-i18n'
import { i18n as messages } from '@crdc/utils'
import messagesFormalization from './messages'

const i18n = createI18n({
  locale: 'pt-Br',
  fallbackLocale: 'pt-Br',
  numberFormats: {
    'pt-Br': {
      currency: {
        style: 'currency',
        currency: 'BRL',
      },
    },
  },
  messages: { 'pt-Br': { ...messages['pt-Br'], ...messagesFormalization } },
})

export { i18n }
