export const routesQualifierManagement = [
  {
    name: 'QualifierManagement',
    path: 'firp/gestao-de-qualificador',
    component: () =>
      import('@/pages/FIRP/QualifierManagement/QualifierManagementPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-firp'],
    },
  },
]
