import { routesGroupsAssignments } from '@/router/modules/group_assignments'
import { routerSigner } from '@/router/modules/signer'
import { routesAttorney } from '@/router/modules/attorney'
import { routesCustomer } from '@/router/modules/customer'
import { routesDocumentsTypes } from '@/router/modules/documents_types'
import { routesContractor } from '@/router/modules/contractor'
import { routesQualifierManagement } from '@/router/modules/qualifier_management'
import { routesPaperManagement } from '@/router/modules/paper_management'
import { routesServiceProvider } from '@/router/modules/service_provider'
import { routesSpouse } from '@/router/modules/spouse'




export const childrenRoutes = [
  ...routesGroupsAssignments,
  ...routerSigner,
  ...routesAttorney,
  ...routesCustomer,
  ...routesDocumentsTypes,
  ...routesQualifierManagement,
  ...routesContractor,
  ...routesPaperManagement,
  ...routesServiceProvider,
  ...routesSpouse
]
