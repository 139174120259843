import Router from '@/components/router'
import { createRouter, createWebHistory } from 'vue-router'
import { childrenRoutes } from './modules/'

const routesChildren = [
  // Rota inicial
  {
    name: 'FormalizationInittialPage',
    path: '',
    component: () => import('@/pages/IndexPage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
  // Workflows
  {
    name: 'WorkflowsFormalization',
    path: 'workflows',
    component: () => import('@/pages/Workflows/Workflows.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-workflow'],
    },
  },
  // Bookkeeping
  {
    name: 'BookkeepingFormalization',
    path: 'bookkeeping',
    component: () => import('@/pages/Bookkeeping/Bookkeeping.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:page-bookkeeping'],
    },
  },
]

const children = [...routesChildren, ...childrenRoutes]

const routes = [
  {
    path: '/:nested*',
    component: Router,
    name: 'formalization',
    children,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
