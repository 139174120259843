export const routesSpouse = [
  // Conjuge
  {
    name: 'Spouse',
    path: 'firp/conjuge/:id',
    component: () => import('@/pages/FIRP/Spouse/SpousePage.vue'),
    meta: {
      requiresAuth: true,
      rules: ['formalization:home-formalization'],
    },
  },
]
