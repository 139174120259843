<template>
  <q-layout view="hHr LpR lFr">
    <CustomDrawer />
    <q-page-container
      style="background: #f6fafd !important; overflow: hidden; min-height: 92vh"
    >
      <router-view></router-view>
      <ToolbarFooter />
    </q-page-container>
  </q-layout>
</template>

<script>
import CustomDrawer from '@/components/customDrawer/CustomDrawer.vue'
import ToolbarFooter from '@/components/footer/Footer.vue'
import { useNotifyStore } from '@/stores/notify'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
  components: { CustomDrawer, ToolbarFooter },
  watch: {
    getError(val) {
      if (val.length > 0) {
        this.$q.notify({
          type: 'negative',
          message: this.getError,
        })
        useNotifyStore().fetchError('')
      }
    },
    getSuccess(val) {
      if (val.length > 0) {
        this.$q.notify({
          type: 'positive',
          message: this.getSuccess,
        })
        useNotifyStore().fetchSuccess('')
      }
    },
  },
  computed: {
    ...mapState(useNotifyStore, {
      getError: 'error',
      getSuccess: 'success',
    }),
  },
})
</script>
