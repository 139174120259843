export default {
  PAGE: {
    CONTRACTOR_MANAGEMENT: 'Contratante | Contratantes',
    SIGNER: 'Assinante | Assinantes',
    CUSTOMER: 'Cliente | Clientes',
    DOCUMENT: 'Documento | Documentos',
  },
  LABELS: {
    CPF_CNPJ: 'CNPJ / CPF',
    NOME_RAZAO_SOCIAL: 'Razão Social / Nome',
    STATUS: 'Status',
    TITLE: 'Deseja realmente excluir este Assinante?',
    SUBTITLE: 'O Assinante será excluído',
    QUALIFIER: 'Qualificador | Qualificadores',
    SIGN_TYPE: 'Tipo de assinatura | Tipos de assinatura',
  },
  FORM: {
    ACTION: {
      CANCEL: 'Cancelar',
      CONFIRM: 'Excluir',
      DOCUMENT: 'Documento | Documentos',
      SIGNER: 'Assinante | Assinantes',
      CUSTOMER: 'Cliente | Clientes',
    },
  },
  SUCCESS: {
    MSG001: 'Excluído com sucesso',
    MSG002: 'Cadastrado com sucesso',
    MSG003: 'Editado com sucesso',
  },
}
